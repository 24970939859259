// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import * as Sentry from "@sentry/browser"
import { Integrations } from "@sentry/tracing"
import Chart from "chart.js/auto"
import annotationPlugin from "chartjs-plugin-annotation"
import LocalTime from "local-time"

LocalTime.start()
Chart.register(annotationPlugin)

if (process.env.SENTRY_DSN) {
  Sentry.init({
    dsn: process.env.SENTRY_DSN,
    environment: process.env.SENTRY_CURRENT_ENV || "development",
    integrations: [new Integrations.BrowserTracing()],
    tracesSampleRate: 0.1,
  })
}

import "bootstrap"
import "@fortawesome/fontawesome-free/css/all"

import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"
import "channels"

Rails.start()
Turbolinks.start()
ActiveStorage.start()

import "../stylesheets/application.scss"

import "controllers"
